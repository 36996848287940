var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 col-xl-6"},[(_vm.getProfilePaymentCurrentPage === 'PaymentCardList')?_c('h3',[_vm._v(" Payment Information ")]):_vm._e(),(_vm.getProfilePaymentCurrentPage === 'PaymentForm')?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setProfilePaymentCurrentPage('PaymentCardList')}}},[_c('i',{staticClass:"fas fa-angle-left"}),_vm._v(" Back to Payment Information ")]):_vm._e()]),_c('div',{staticClass:"col-6 col-lg-6 col-xl-6 d-none d-lg-block d-xl-block"},[(
          _vm.getProfilePaymentCurrentPage === 'PaymentCardList' &&
            _vm.facilityInfo &&
            !_vm.facilityInfo.fortis
        )?_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-primary btn-w-160",on:{"click":function($event){return _vm.setProfilePaymentCurrentPage('PaymentForm')}}},[_vm._v(" ADD NEW ")])]):_vm._e()])]),_c('br'),_c('div',[_c(_vm.getProfilePaymentCurrentPage,{tag:"component"})],1),_c('br'),(
      _vm.getProfilePaymentCurrentPage === 'PaymentCardList' &&
        _vm.facilityInfo &&
        !_vm.facilityInfo.fortis
    )?_c('div',{staticClass:"col-12 col-lg-6 col-xl-6 d-lg-none"},[_c('button',{staticClass:"btn btn-primary btn-w-100p",on:{"click":function($event){return _vm.setProfilePaymentCurrentPage('PaymentForm')}}},[_vm._v(" ADD NEW 3 ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }